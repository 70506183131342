<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col
                cols="12"
                xs="12" 
                sm="12" 
                md="12"
                class="ml-3"
            >
                <h5 style="font-weight: 900;">{{this.speed ? (this.speed.header.length > 0 ? 'CGL2, Crew ' + this.speed.header[0].crew : 'Pls wait...') : 'Pls wait...'}}</h5>
                <h6 class="indigo-text m-0">{{this.speed ? (this.speed.header.length > 0 ? 'Real Time Data, Last Update @ ' + this.speed.header[0].date : 'Pls wait...') : 'Pls wait...'}}</h6>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col
                cols="12"
                xs="12" 
                sm="4" 
                md="4"
            >
                <v-card class="pa-md-5">   
                    <v-card-text>
                        <v-row align="center" justify="center">
                            <v-col
                                cols="4"
                                class="text-center"
                            >   
                                <v-icon color="success">
                                    mdi-album
                                </v-icon>
                                <h4 class="mb-0">{{this.speed ? (this.speed.realtime.length > 0 ? this.speed.realtime[0].ENTRY_LENGTH : 'Pls wait...') : 'Pls wait...'}}</h4>
                                {{this.speed ? (this.speed.realtime.length > 0 ? this.speed.realtime[0].ENCOILID : 'Pls wait...') : 'Pls wait...'}}
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                xs="12" 
                sm="4" 
                md="4"
            >
                <v-card class="pa-md-5">   
                    <v-card-text>
                        <v-row align="center" justify="center">
                            <v-col
                                cols="4"
                                class="text-center"
                            >   
                                <v-icon color="success">
                                    mdi-chart-donut-variant
                                </v-icon>
                                <h4 class="mb-0">{{this.speed ? (this.speed.consume.length > 0 ? $store.getters.convertToCurrency(this.speed.consume[0].ENDPOS) : 'Pls wait...') : 'Pls wait...'}}</h4>
                                Consume
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                xs="12" 
                sm="4" 
                md="4"
            >
                <v-card class="pa-md-5">   
                    <v-card-text>
                        <v-row align="center" justify="center">
                            <v-col
                                cols="4"
                                class="text-center"
                            >   
                                <v-icon color="success">
                                    mdi-record
                                </v-icon>
                                <h4 class="mb-0">{{this.speed ? (this.speed.LENPROD.length > 0 ? $store.getters.convertToCurrency(this.speed.LENPROD[0].LENPROD) : 'Pls wait...') : 'Pls wait...'}}</h4>
                                CoilProd
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                xs="12" 
                sm="4" 
                md="4"
            >
                <v-card class="pa-md-5">   
                    <v-card-text class="cyan lighten-5">
                        <h6 style="font-weight: 600;" class="cyan-text text-darken-3 m-0"><i class="material-icons">timer</i> Real Time Data</h6>
                    </v-card-text>
                    <v-row class="mt-1">
                        <v-list class="transparent">
                            <v-list-item style="min-height:30px">
                                <v-list-item-title>Entry Thickness</v-list-item-title>

                                <v-list-item-subtitle class="text-right cyan-text text-darken-3" style="font-weight: 600;">
                                    {{this.speed ? (this.speed.realtime.length > 0 ? $store.getters.convertToCurrency(this.speed.realtime[0].ENTRY_THICK) : 'Pls wait...') : 'Pls wait...'}}
                                </v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item style="min-height:30px">
                                <v-list-item-title>Entry Width</v-list-item-title>

                                <v-list-item-subtitle class="text-right cyan-text text-darken-3" style="font-weight: 600;">
                                    {{this.speed ? (this.speed.realtime.length > 0 ? $store.getters.convertToCurrency(this.speed.realtime[0].ENTRY_WIDTH) : 'Pls wait...') : 'Pls wait...'}}
                                </v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item style="min-height:30px">
                                <v-list-item-title>Grade</v-list-item-title>

                                <v-list-item-subtitle class="text-right cyan-text text-darken-3" style="font-weight: 600;">
                                    {{this.speed ? (this.speed.realtime.length > 0 ? this.speed.realtime[0].GRADE : 'Pls wait...') : 'Pls wait...'}}
                                </v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item style="min-height:30px">
                                <v-list-item-title>AZ</v-list-item-title>

                                <v-list-item-subtitle class="text-right cyan-text text-darken-3" style="font-weight: 600;">
                                    {{this.speed ? (this.speed.az.length > 0 ? this.speed.az[0].AZ : '-') : 'Pls wait...'}}
                                </v-list-item-subtitle>
                            </v-list-item>
                        </v-list>
                    </v-row>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                xs="12" 
                sm="4" 
                md="4"
            >
                <v-card class="pa-md-5">   
                    <div id="container1" style="height: 189px; width: 100%;"></div>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                xs="12" 
                sm="4" 
                md="4"
            >
                <v-card class="pa-md-5">   
                    <div id="container2" style="height: 189px; width: 100%;"></div>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-5" align="center" justify="center">
            <v-col
                cols="12"
                xs="12" 
                sm="4" 
                md="4"
            >
                <h5 class="text-center" style="font-weight: 900;">Last Production Summary :</h5>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col
                cols="12"
                xs="12" 
                sm="12" 
                md="12"
                class="ml-3"
            >
                <h6 class="indigo-text m-0">{{this.dashboardContent ? (this.dashboardContent.length > 0 ? 'Last Update @ ' + this.dashboardContent[0].END_DATE + ' (updated every 1 mins)' : 'Pls wait...') : 'Pls wait...'}}</h6>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col
                cols="12"
                xs="12" 
                sm="4" 
                md="4"
            >
                <v-card class="pa-md-5">   
                    <v-card-text class="blue lighten-5">
                        <h6 style="font-weight: 600;" class="blue-text text-darken-3 m-0"><i class="material-icons">link</i> Entry</h6>
                    </v-card-text>
                    <v-row class="mt-1">
                        <v-list class="transparent">
                            <v-list-item style="min-height:26px">
                                <v-list-item-title>CRCID</v-list-item-title>

                                <v-list-item-subtitle class="text-right blue-text text-darken-3" style="font-weight: 600;">
                                    {{this.dashboardContent ? (this.dashboardContent.length > 0 ? this.dashboardContent[0].crc_id : 'Pls wait...') : 'Pls wait...'}}
                                </v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item style="min-height:26px">
                                <v-list-item-title>Welded Time</v-list-item-title>

                                <v-list-item-subtitle class="text-right blue-text text-darken-3" style="font-weight: 600;">
                                    {{this.dashboardContent ? (this.dashboardContent.length > 0 ? this.dashboardContent[0].welded_time : 'Pls wait...') : 'Pls wait...'}}
                                </v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item style="min-height:26px">
                                <v-list-item-title>Thickness</v-list-item-title>

                                <v-list-item-subtitle class="text-right blue-text text-darken-3" style="font-weight: 600;">
                                    {{this.dashboardContent ? (this.dashboardContent.length > 0 ? $store.getters.convertToCurrency(this.dashboardContent[0].ENTHICK) + ' mm' : 'Pls wait...') : 'Pls wait...'}}
                                </v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item style="min-height:26px">
                                <v-list-item-title>Width</v-list-item-title>

                                <v-list-item-subtitle class="text-right blue-text text-darken-3" style="font-weight: 600;">
                                    {{this.dashboardContent ? (this.dashboardContent.length > 0 ? $store.getters.convertToCurrency(this.dashboardContent[0].ENWIDTH) + ' mm' : 'Pls wait...') : 'Pls wait...'}}
                                </v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item style="min-height:26px">
                                <v-list-item-title>Weight</v-list-item-title>

                                <v-list-item-subtitle class="text-right blue-text text-darken-3" style="font-weight: 600;">
                                    {{this.dashboardContent ? (this.dashboardContent.length > 0 ? $store.getters.convertToCurrency(this.dashboardContent[0].ENWEIGHT)+ ' Kg' : 'Pls wait...') : 'Pls wait...'}}
                                </v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item style="min-height:26px">
                                <v-list-item-title>Porpos</v-list-item-title>

                                <v-list-item-subtitle class="text-right blue-text text-darken-3" style="font-weight: 600;">
                                    {{this.dashboardContent ? (this.dashboardContent.length > 0 ? this.dashboardContent[0].ENPORPOS : 'Pls wait...') : 'Pls wait...'}}
                                </v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item style="min-height:26px">
                                <v-list-item-title>AVG Speed</v-list-item-title>

                                <v-list-item-subtitle class="text-right blue-text text-darken-3" style="font-weight: 600;">
                                    {{this.dashboardContent ? (this.dashboardContent.length > 0 ? $store.getters.convertToCurrency(this.dashboardContent[0].ENLINE_SPEED) + ' mpm': 'Pls wait...') : 'Pls wait...'}}
                                </v-list-item-subtitle>
                            </v-list-item>
                        </v-list>
                    </v-row>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                xs="12" 
                sm="4" 
                md="4"
            >
                <v-card class="pa-md-5">   
                    <v-card-text class="orange lighten-5">
                        <h6 style="font-weight: 600;" class="orange-text text-darken-3 m-0"><i class="material-icons">center_focus_strong</i> Center</h6>
                    </v-card-text>
                    <v-row class="mt-1">
                        <v-list class="transparent">
                            <v-list-item style="min-height:30px">
                                <v-list-item-title>Grade</v-list-item-title>

                                <v-list-item-subtitle class="text-right orange-text text-darken-3" style="font-weight: 600;">
                                    {{this.dashboardContent ? (this.dashboardContent.length > 0 ? this.dashboardContent[0].GRADE : 'Pls wait...') : 'Pls wait...'}}
                                </v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item style="min-height:30px">
                                <v-list-item-title>AZ</v-list-item-title>

                                <v-list-item-subtitle class="text-right orange-text text-darken-3" style="font-weight: 600;">
                                    {{this.dashboardContent ? (this.dashboardContent.length > 0 ? $store.getters.convertToCurrency(this.dashboardContent[0].AZ) : 'Pls wait...') : 'Pls wait...'}}
                                </v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item style="min-height:30px">
                                <v-list-item-title>AVG Speed</v-list-item-title>

                                <v-list-item-subtitle class="text-right orange-text text-darken-3" style="font-weight: 600;">
                                    {{this.dashboardContent ? (this.dashboardContent.length > 0 ? $store.getters.convertToCurrency(this.dashboardContent[0].CENLINE_SPEED) + ' mm' : 'Pls wait...') : 'Pls wait...'}}
                                </v-list-item-subtitle>
                            </v-list-item>
                        </v-list>
                    </v-row>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                xs="12" 
                sm="4" 
                md="4"
            >
                <v-card class="pa-md-5">   
                    <v-card-text class="green lighten-5">
                        <h6 style="font-weight: 600;" class="green-text text-darken-3 m-0"><i class="material-icons">cancel</i> Exit</h6>
                    </v-card-text>
                    <v-row class="mt-1">
                        <v-list class="transparent">
                            <v-list-item style="min-height:30px">
                                <v-list-item-title>CoilID</v-list-item-title>

                                <v-list-item-subtitle class="text-right green-text text-darken-3" style="font-weight: 600;">
                                    {{this.dashboardContent ? (this.dashboardContent.length > 0 ? this.dashboardContent[0].Coil_id : 'Pls wait...') : 'Pls wait...'}}
                                </v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item style="min-height:30px">
                                <v-list-item-title>Weight</v-list-item-title>

                                <v-list-item-subtitle class="text-right green-text text-darken-3" style="font-weight: 600;">
                                    {{this.dashboardContent ? (this.dashboardContent.length > 0 ? $store.getters.convertToCurrency(this.dashboardContent[0].EX_WEIGHT) + ' Kg': 'Pls wait...') : 'Pls wait...'}}
                                </v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item style="min-height:30px">
                                <v-list-item-title>Length</v-list-item-title>

                                <v-list-item-subtitle class="text-right green-text text-darken-3" style="font-weight: 600;">
                                    {{this.dashboardContent ? (this.dashboardContent.length > 0 ? $store.getters.convertToCurrency(this.dashboardContent[0].EX_LEN) + ' m' : 'Pls wait...') : 'Pls wait...'}}
                                </v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item style="min-height:30px">
                                <v-list-item-title>Quality</v-list-item-title>

                                <v-list-item-subtitle class="text-right green-text text-darken-3" style="font-weight: 600;">
                                    {{this.dashboardContent ? (this.dashboardContent.length > 0 ? this.dashboardContent[0].EX_QUALITY : 'Pls wait...') : 'Pls wait...'}}
                                </v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item style="min-height:30px">
                                <v-list-item-title>CuttingTime</v-list-item-title>

                                <v-list-item-subtitle class="text-right green-text text-darken-3" style="font-weight: 600;">
                                    {{this.dashboardContent ? (this.dashboardContent.length > 0 ? this.dashboardContent[0].END_DATE : 'Pls wait...') : 'Pls wait...'}}
                                </v-list-item-subtitle>
                            </v-list-item>
                        </v-list>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
  export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Dashboard',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Live Data Production',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            tab: 'tab-1',
            speed: null,
            dashboardContent: null,
            x: '',
            interval: null,
        }
    },
    mounted(){
        this.getDashboard()
        this.getDashboardSpeed()
    },
    beforeDestroy() {
    // prevent memory leak
        clearInterval(this.interval)
    },
    created() {
    // update the time every second
        this.interval = setInterval(() => {
            this.getDashboard()
            this.getDashboardSpeed()
        }, 60000)
    },
    methods:{
        async getDashboard(){
            await axios.get(`${process.env.VUE_APP_URL}/api/sr`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.dashboardContent = res.data
            })
        },
        time_convert(num){ 
    
            var hours = Math.floor(num / 60)+ " hours";  
            var minutes = num % 60;
            return hours + ", " + minutes + " minutes";    

        },
        async getDashboardSpeed(){
            await axios.get(`${process.env.VUE_APP_URL}/api/sr/speed`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.speed = res.data

                var dt1 =  [];
                var dt2 =  [];
                var tes = '';

                for (var i = 0; i < this.speed.updateSpeed.length; i++) {
                    dt1.push({ label: this.speed.updateSpeed[i].date, y: parseFloat(this.speed.updateSpeed[i].ENSpeed_sec.slice(0, -1)) });
                    dt2.push({ label: this.speed.updateSpeed[i].date, y: parseFloat(this.speed.updateSpeed[i].CENSpeed_sec.slice(0, -1)) });
                    var tes = parseInt(this.speed.updateSpeed[i].sub_min);
                    var lastUpdate1 = this.speed.updateSpeed[i].date2;
                    var lastUpdate2 = this.speed.updateSpeed[i].date2;
                }

                if (tes > 10 ) {

                    if (tes > 60 ) {

                        var subtitle1 = "Stop line in : " +this.time_convert(tes);
                        var subtitle2 = "Stop line in : " +this.time_convert(tes);
                    }

                    else {

                        var subtitle1 = "Stop line in : " +tes+ " minutes";
                        var subtitle2 = "Stop line in : " +tes+ " minutes";

                    }
                    
                    var subtitle_color1 = "red";
                    var title1 = "Entry Speed";
                    var container1 = "container1";
                    var color1 = "#0e9aa7";
                    var name1 = "Entry Speed";

                    var subtitle_color2 = "red";
                    var title2 = "Center Speed";
                    var container2 = "container2";
                    var color2 = "#c70039";
                    var name2 = "Center Speed";

                    this.getChart(dt1, title1, subtitle1, subtitle_color1, lastUpdate1, container1, color1, name1 );
                    this.getChart(dt2, title2, subtitle2, subtitle_color2, lastUpdate2, container2, color2, name2 );
                }

                else {

                    var subtitle1 = "-";
                    var subtitle_color1 = "black";
                    var title1 = "Entry Speed";
                    var container1 = "container1";
                    var color1 = "#0e9aa7";
                    var name1 = "Entry Speed";

                    var subtitle2 = "-";
                    var subtitle_color2 = "black";
                    var title2 = "Center Speed";
                    var container2 = "container2";
                    var color2 = "#c70039";
                    var name2 = "Center Speed";

                    this.getChart(dt1, title1, subtitle1, subtitle_color1, lastUpdate1, container1, color1, name1 );
                    this.getChart(dt2, title2, subtitle2, subtitle_color2, lastUpdate2, container2, color2, name2 );

                }
            })
        },
        getChart(dt, title, subtitle, subtitle_color, lastUpdate, container, color, name){
            var chart = new CanvasJS.Chart(container, {
                        animationEnabled: false,
                        theme: "light2",
                        title: {
                            text: title, 
                            fontFamily: "Calibri",
                            fontSize: 20
                        },
                        subtitles:[
                            {
                                text: subtitle,
                                fontFamily: "Calibri",
                                fontColor: subtitle_color,
                                fontSize: 20
                            }
                        ],
                        exportEnabled: true,
                        axisY: {
                            crosshair: {
                                enabled: true,
                                snapToDataPoint: true
                            },
                            title: "mpm",
                        },
                        toolTip:{
                            shared:true
                        },
                        legend: {
                            fontSize: 11,
                            itemMaxWidth: 150,
                            itemWrap: true ,
                            cursor: "pointer",
                            itemclick: this.toggleDataSeries
                        },
                        data: [
                            {
                                type: "line",
                                showInLegend: true,
                                name: name,
                                indexLabel: "{y}",
                                indexLabelFontSize: 12,
                                indexLabelFontFamily: "calibri",
                                color: color
                            }
                        ]
                    });

            chart.options.data[0].dataPoints = dt;

            if (this.x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 6;
                }
                chart.render();
            }

            chart.render();
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        }
    },
  }
</script>